import gameImport from './../game';

import joker from './../../images/game2/slot/img-joker.png';
import spadegaming from './../../images/game2/slot/img-spadegaming.png';
import fachai from './../../images/game2/slot/img-fachai.png';
import jili from './../../images/game2/slot/img-jili.png';
import redtiger from './../../images/game2/slot/img-redtiger.png';
import king from './../../images/game2/slot/img-kingmaker.png';
import pragmatic from './../../images/game2/slot/img-pragmaticplay.png';
import play8 from './../../images/game2/slot/img-play8.png';
//import genesis from './../../images/game2/slot/img-genesis.png';
import fastspin from './../../images/game2/slot/img-fastspin.png';
import dragon from './../../images/game2/slot/img-dragonsoft.png';
import nlc from './../../images/game2/slot/img-nolimitcity.png';
//import yesgaming from './../../images/game2/slot/yes-gaming-long-min.png';
import jdbslot from './../../images/game2/slot/jdb-long-min.png';
import idg from './../../images/game2/slot/IDG-long-min.png';
import ygr from './../../images/game2/slot/img-ygr-d.png';
import icnIdealGaming from './../../icons/provider/idealgaming.png';

var gameMenu = {
  name: 'SLOT',
  items: [
    {
      name: 'Joker',
      isFullWidth: false,
      provider: 'joker',
      icon: gameImport.find((x) => x.code.toString() === '35').icon,
      code: '35',
      img: [{ langId: 0, src: joker }],
      img2: [{ langId: 0, src: joker }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Spade Gaming',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-205',
      img: [{ langId: 0, src: spadegaming }],
      img2: [{ langId: 0, src: spadegaming }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Fa Chai',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-207',
      img: [{ langId: 0, src: fachai }],
      img2: [{ langId: 0, src: fachai }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Jili',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-208',
      img: [{ langId: 0, src: jili }],
      img2: [{ langId: 0, src: jili }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Red Tiger',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-202',
      img: [{ langId: 0, src: redtiger }],
      img2: [{ langId: 0, src: redtiger }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Kingmaker',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-204',
      img: [{ langId: 0, src: king }],
      img2: [{ langId: 0, src: king }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Pragmatic Play',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-203',
      img: [{ langId: 0, src: pragmatic }],
      img2: [{ langId: 0, src: pragmatic }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Play8',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-210',
      img: [{ langId: 0, src: play8 }],
      img2: [{ langId: 0, src: play8 }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    // {
    //   name: 'Genesis Slot',
    //   provider: 'eBet',
    //   history: 'eBet',
    //   icon: gameImport.find((x) => x.code.toString() === '64').icon,
    //   code: '64-101',
    //   img: [{ langId: 0, src: genesis }],
    //   img2: [{ langId: 0, src: genesis }],
    //   labelName: '',
    //   parentName: '',
    // },
    {
      name: 'Fast Spin',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-212',
      img: [{ langId: 0, src: fastspin }],
      img2: [{ langId: 0, src: fastspin }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'Dragoon Soft',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-213',
      img: [{ langId: 0, src: dragon }],
      img2: [{ langId: 0, src: dragon }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'No Limit City',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-214',
      img: [{ langId: 0, src: nlc }],
      img2: [{ langId: 0, src: nlc }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'iDealGaming',
      isFullWidth: false,
      provider: 'iDealGaming',
      icon: icnIdealGaming,
      code: '100',
      img: [{ langId: 0, src: idg }],
      img2: [{ langId: 0, src: idg }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'JDB Slot',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-201',
      img: [{ langId: 0, src: jdbslot }],
      img2: [{ langId: 0, src: jdbslot }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'YGR',
      isFullWidth: false,
      provider: 'YGR',
      icon: gameImport.find((x) => x.code.toString() === '101').icon,
      code: '101',
      img: [{ langId: 0, src: ygr }],
      img2: [{ langId: 0, src: ygr }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
  ],
};

export default gameMenu;
